<!-- <button nz-button nzType="primary" (click)="downloadFile()" *ngIf="downloadLink">
    <span nz-icon nzType="download"></span>
    Export
</button> -->


<!-- <div class="form-input"> -->

  <!-- <div class="form-input"> -->
    <form nz-form [formGroup]="viewform"  >

      <div nz-row [nzGutter]="24">



            <nz-form-item>
              <nz-form-label [nzSpan]="11" nzRequired>OperatorTemplateId</nz-form-label>
              <nz-form-control [nzSpan]="12" nzValidatingTip="Validating...">
                <input nz-input formControlName="operatorTemplateId" placeholder="Enter OperatorTemplateId" (input)="anyText()" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="9" nzRequired>Template Title</nz-form-label>
              <nz-form-control [nzSpan]="12" nzValidatingTip="Validating...">
                <input nz-input formControlName="templateTitle" placeholder="Enter Template Title" (input)="anyText()" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control  [nzSpan]="10">
                <button nz-button nzType="primary" [disabled]="subdisabled" (click)="search()">Submit</button>
              </nz-form-control>
            </nz-form-item>

        </div>

                <!-- <button nzType="primary" type="submit" (click)="search()" class="ml-7" mat-flat-button
                style="align-self: center;">
                <span class="ml-2 mr-1">Search</span>
                </button> -->





              <!-- <nz-form-item>
                <nz-form-control nzErrorTip="Please input your username!">
              <nz-input-group nzPrefixIcon="user">

              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Password!">
              <nz-input-group nzPrefixIcon="lock">

              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>



            </nz-form-control>
          </nz-form-item>
            </div> -->










              <!-- <label class="form-label" for="form1">
              </label>

                  <button type="submit" (click)="search()" class="ml-7" mat-flat-button
                  style="align-self: center;">
                  <span class="ml-2 mr-1">Search</span>
                  </button>
          -->

<!--
<app-simple-table  *ngIf="listOfData" [listOfData]="listOfData" [listOfColumn]="listOfColumn" [pagination]="true"
[type]="'content-template'" (deleteTriggred)="deleteTemplate($event); refresh();" ></app-simple-table> -->

<app-simple-table  *ngIf="listOfData" [listOfData]="listOfData" [listOfColumn]="listOfColumn" [pagination]="true"
[type]="'content-template'" (editTrigger)="showEditModal($event); refresh();" ></app-simple-table>

<div class="carousel-controls" style="display: flex; justify-content: end;margin-top: 5px;">
  <!-- <button nz-button nzType="primary"  class="wes" (click)="prev()">Previous</button> -->
  <!-- [disabled]="disablePaginationpre" -->
  <!-- <button nz-button nzType="primary"  (click)="next()">Next</button> -->
  <!-- [disabled]="disablePagination" -->
</div>


    </form>
  <!-- </div> -->
<!-- <form nz-form="searchform" > -->








  <!-- <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Template Title</th>
        <th scope="col">Last</th>
        <th scope="col">Handle</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataList">
        <th scope="row">1</th>
        <td>{{data.templateId}}</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>

     </tbody>
  </table>  -->




    <!-- <app-simple-table *ngIf="dataList" [listOfData]="dataList"[listOfColumn]="listOfColumn" [pagination]="true"
    [type]="'content-template-search'" (deleteTriggred)="deleteTemplate($event)"></app-simple-table> -->


